module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-82ae11fd45/0/cache/gatsby-plugin-manifest-npm-3.14.0-2322bdf147-f8ac4f7519.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f13b819d714fb922a6409a4af90e9a3f"},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-4ed4678a84.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto"]}},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-image-virtual-12e521267f/0/cache/gatsby-plugin-image-npm-1.14.2-96fe5d8daa-65e7e1b6e5.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-google-tagmanager-virtual-be10aac810/0/cache/gatsby-plugin-google-tagmanager-npm-3.14.0-2e46f23560-002ccb1e63.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K94GK4H","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"jmOwH1FNaFEzkyuSYXwkUg","gtmPreview":"env-1","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
