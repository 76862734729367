// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-nl-tsx": () => import("./../../../src/pages/index.nl.tsx" /* webpackChunkName: "component---src-pages-index-nl-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-cv-page-template-tsx": () => import("./../../../src/templates/CvPageTemplate.tsx" /* webpackChunkName: "component---src-templates-cv-page-template-tsx" */),
  "component---src-templates-team-page-template-tsx": () => import("./../../../src/templates/TeamPageTemplate.tsx" /* webpackChunkName: "component---src-templates-team-page-template-tsx" */)
}

